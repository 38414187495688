@import url('./DirectionsList_960.css');
@import url('./DirectionsList_375.css');


@media screen and (min-width: 1123px) {
   
    .directionsList {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .direction {
        display: flex;
        gap: 10px;
    }

    .direction:nth-child(even) {
        flex-direction: row-reverse;
    }

    .direction__name {
        height: 400px;
        width: 100%;
        max-width: 509px;
        padding: 40px;
        background-color: #fff;
        border-radius: 20px;
    }

    .direction__name h2 {
        max-width: 291px;
        margin-bottom: 18px;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
    }

    .direction__name p {
        max-width: 398px;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .direction__slider {
        height: 400px;
        width: 100%;
        max-width: 827px;
        border-radius: 20px;
        color: #fff;
        position: relative;
        overflow: hidden;
    }

    .direction__slide {
        height: 400px;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .direction__slide img {
        width: 100%;
        height: 100%;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        object-fit:cover;
    }

    .direction__slider-arrows {
        height: 60px;
        width: 120px;
        border-radius: 16px;
        position: absolute;
        top: 315px;
        right: 25px;
        display: flex;
    }

    .direction__slider-arrow-left {
        border-radius: 16px 0px 0px 16px;
        background: url('../../../Media/SliderArrowLeft.svg') center no-repeat;
    }

    .direction__slider-arrow-right {
        border-radius: 0px 16px 16px 0px;
        background: url('../../../Media/SliderArrowRight.svg') center no-repeat;
    }

    .direction__slider-arrows > .direction__slider-arrow-left, .direction__slider-arrow-right {
        height: 100%;
        width: 50%;
        background-size: 10px 20px;
        background-color: rgba(189, 189, 189, 0.4);
        backdrop-filter: blur(4px);
        cursor: pointer;
    }

}
