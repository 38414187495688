@import url("./ReadyMechanics_744.css");
@import url("./ReadyMechanics_375.css");


@media screen and (min-width: 960px) {
    
    .readyMechanics__wrapper {
    background-color: #141414;
    padding: 40px 0px 60px 0px;
    border-radius: 20px;
    }

    .readyMechanics__title {
        color: #fff;
        font-weight: 400;
        font-size: 46px;
        line-height: 94%;
        letter-spacing: -0.03em;
        margin-left: 36px;
        margin-bottom: 18px;
    }

    .readyMechanics__caption {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #BDBDBD;
        margin-left: 40px;
        max-width: 245px;
        margin-bottom: 161px;
    }

    .readyMechanics__list {
        padding: 0px 40px 0px 38px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .readyMechanics__list-item {
        flex: 49%;
        max-width: 50%;
        margin-bottom: 40px;
    }

}

