@import url('./Header_744.css');

@media screen and (min-width: 960px) {
    
    .header {
        padding-top: 5px;
        position: fixed;
        top: -70px;
        width: 100%;
        z-index: 999;
        box-sizing: border-box;
        display: none;
        background: linear-gradient(to bottom, #E9E9E9 40px, transparent 40px);
        animation: hide-header 0.5s;
    }
    
    .active {
        display: block;
        animation: show-header 0.5s;
        top: 0;
    }
    
    .hidden {
        display: block;
        animation: hide-header 0.5s;
    }
    
    @keyframes show-header {
        from {
            opacity: 0%;
            top: -70px;
          }
        
        to {
            opacity: 100%;
            top: 0;
        }
    }
    
    @keyframes hide-header {
        from {
            opacity: 100%;
            top: 0;
          }
        
        to {
            opacity: 0%;
            top: -70px;
        }
    }
    
    .header__content {
        height: 65px;
        background: #fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 40px;
    }
    
    .header__logo-link {
        height: 26px;
        width: 110px;
    }
    
    .header__logo {
        height: 26px;
        width: 110px;
    }
    
    .header__nav-list {
        display: flex;
        gap: 25px;
    }
    
    .header__nav-list-item:hover {
        text-decoration: underline;
    }
    
    .header__service-list {
        padding-left: 50px;
        display: flex;
        gap: 25px;
    }
    
    .header__service-list-item:first-child {
        color: #BDBDBD;
    }
    
    .header__service-list-item:hover {
        text-decoration: underline;
    }
    
    .header__service-list-item:first-child:hover {
        text-decoration: none;
    }
    
    .header__presentation:hover {
        text-decoration: underline;
    }

}