@media screen and (max-width: 743px) {
 
    .solutionsList {
        display: flex;
        width: 100%;
        gap: 5px;
        flex-wrap: wrap;
        margin-bottom: 5px;
    }

    .solution {
        height: 333px;
        padding: 20px 5px 5px 5px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 100%;
    }

    .solution-big {
        flex: 100%;
    }

    .solution h3 {
        margin: 0px 19px;
        max-width: 407px;
        font-weight: 400;
        font-size: 22px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .solution__img {
        height: 178px;
        width: 100%;
        background-color: #BDBDBD;
        border-radius: 16px;
    }

}

