@media screen and (max-width: 744px) {

    .feedbackForm {
        height: 573px;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 20px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .feedbackForm form {
        flex-grow: 1;
    }
    
    .feedbackForm__heading {
        height: 64px;
        display: flex;
        align-items: center;
        padding-left: 25px;
    }
    
    .feedbackForm__heading-title {
        font-family: 'TT Hoves', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #6D6D6D;
        margin-right: 10px;
    }
    
    .feedbackForm__heading-request {
        height: 36px;
        width: 87px;
        padding: 7px 14px 9px 14px;
        background: #E9E9E9;
        border-radius: 30px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414
    }
    
    .feedbackForm__input-wrapper {
        height: 64px;
        width: 100%;
        border-top: 1px solid #E7E7E7;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        display: flex;
        align-items: center;
        padding: 0px 25px 0px 23px;
    }

    .feedbackForm__input {
        font-family: 'TT Hoves', sans-serif;
        width: 100%;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
        box-sizing: border-box;
        padding-right: 20px;
        resize: none;
    }
    
    .feedbackForm__input::placeholder {
        color: #6D6D6D;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
    }
    
    .textarea {
        align-items: flex-start;
        box-sizing: border-box;
        padding-top: 22px;
    }

    .textarea .feedbackForm__required {
        margin-top: 7px;
    }

    .feedbackForm__required {
        align-self: flex-start;
        margin-top: 22px;
    }

    .feedbackForm__bottom {
        width: 100%;
        height: 76px;
        padding: 0px 25px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .feedbackForm__rules {
        max-width: 223px;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .feedbackForm__rules a {
        text-decoration: underline;
    }

    .feedbackForm__button {
        height: 65px;
        width: 65px;
        border-radius: 20px;
        background: url('../../../Media/ButtonArrow.svg') center no-repeat;
        background-size: 23px;
        background-color: #141414;
        cursor: pointer;
        transition: all 0.2s;
    }

    .feedbackForm__button:hover {
        height: 71px;
        width: 71px;
    }
}