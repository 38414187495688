@media screen and (min-width: 744px) and (max-width: 959px) {
    
    .competenceHead__wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 0px;
    }
    
    .competenceHead__banner {
        height: 350px;
        width: 100%;
        padding: 30px 0px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .competenceHead__banner-top {
        display: none;
    }
    
    .competenceHead__title {
        margin-left: 27px;
        margin-bottom: 15px;
        font-size: 42px;
        max-width: 173px;
        transition: all 0.5s;
    }
    
    .competenceHead__label {
        margin-left: 29px;
        max-width: 430px;
        font-size: 14px;
        max-width: 316px;
        transition: all 0.5s;
    }
    
    .competenceHead__card {
        height: 500px;
        width: 100%;
        padding: 35px 30px 40px 30px;
        background-color: #141414;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .competenceHead__card p {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #fff;
        transition: all 0.5s;
    }
    
    .competenceHead__card-label-top {
        align-self: flex-end;
        margin-bottom: 141px;
    }
    
    .competenceHead__card-icon {
        height: 120px;
        width: 120px;
        border-radius: 33px;
        background-size: 50px;
        margin-bottom: 122px;
        /* transition: all 0.5s; */
    }
    
    .competenceHead__card-label-bottom {
        align-self: flex-start;
        max-width: 182px;
    }

}
