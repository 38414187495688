@media screen and (min-width: 960px) {
    
    .atvantages__wrapper {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
    }
    
    .atvantage {
        height: 329px;
        width: 100%;
        max-width: 329px;
        padding: 40px 30px 30px 40px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .atvantage h3 {
        max-width: 196px;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }
    
    .atvantage__icon {
        height: 24px;
        width: 24px;
        align-self: flex-end;
    }

}


@media screen and (min-width: 744px) and (max-width: 959px) {

    .atvantages__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 10px;
    }
    
    .atvantage {
        height: 329px;
        flex: calc(50% - 5px);
        padding: 40px 30px 30px 40px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .atvantage h3 {
        max-width: 196px;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }
    
    .atvantage__icon {
        height: 24px;
        width: 24px;
        align-self: flex-end;
    }

}

@media screen and (max-width: 743px) {
    .atvantages__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-bottom: 5px;
    }
    
    .atvantage {
        height: 180px;
        flex: calc(50% - 5px);
        padding: 20px 26px 30px 24px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .atvantage h3 {
        max-width: 110px;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }
    
    .atvantage__icon {
        height: 24px;
        width: 24px;
        align-self: flex-end;
    }
}