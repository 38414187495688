@import url('./CaseInfo_375.css');

@media screen and (min-width: 744px) {

    .caseInfo__wrapper {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
    }

    .caseInfo__main {
        height: 640px;
        width: 100%;
        max-width: 509px;
        padding: 40px 0px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
    }

    .caseInfo__main-inf {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .caseInfo__main-inf h1 {
        margin-left: 37px;
        font-weight: 400;
        font-size: 46px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #141414;
        margin-bottom: 18px;
    }

    .caseInfo__main-inf h2 {
        margin-left: 39px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .caseInfo__rating {
        margin-left: 40px;
        font-size: 16px;
        color: #141414;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .caseInfo__rating-star {
        height: 14px;
        width: 15px;
        background: url('../../../Media/RatingStar.svg') center no-repeat;
        background-size: 15px 14px;
    }

    .caseInfo__year {
        margin-right: 40px;
        align-self: flex-end;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #141414;
    }

    .caseInfo__more {
        width: 100%;
        max-width: 827px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .caseInfo__task {
        height: 240px;
        width: 100%;
        padding: 40px;
        background-color: #fff;
        border-radius: 20px;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .caseInfo__task h2,p {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .caseInfo__task h2 {
        color: #141414;
    }

    .caseInfo__task p {
        color: #6D6D6D;
        max-width: 588px;
    }

    .caseInfo__term {
        height: 390px;
        width: 100%;
        padding: 40px 32px 9px 40px;
        border-radius: 20px;
        background-color: #141414;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .caseInfo__term h2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #BDBDBD;
    }

    .caseInfo__term p {
        font-weight: 400;
        font-size: 300px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        align-self: end;
    }

}

