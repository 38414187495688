@media screen and (max-width: 959px) {
    .writeUs__wrapper {
        height: 393px;
        padding: 30px 25px 29px 25px;
        background-color: #141414;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .writeUs__title h2 {
        margin-bottom: 18px;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #fff;
    }
    
    .writeUs__title p {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #BDBDBD;
    }
    
    .writeUs__bottom {
        display: flex;
        justify-content: space-between;
    }
    
    .writeUs__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        width: 308px;
        background-color: #fff;
        color: #141414;
        border-radius: 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        letter-spacing: -0.02em;
        transition: all 0.5s;
    }
    
    .writeUs__cutaway {
        display: flex;
    }
    
    .writeUs__cutaway p {
        align-self: flex-end;
        max-width: 104px;
        text-align: right;
        margin-right: 20px;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: #141414;
    }
    
    .writeUs__qr-code {
        height: 65px;
        width: 65px;
        background: url('../../../Media/PresentationQr.svg') center no-repeat;
        background-size: contain;
        align-self: flex-end;
    }
}