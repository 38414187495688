@media screen and (min-width: 744px) and (max-width: 959px) {

    .lineUp__wrapper {
        background-color: #fff;
        padding: 25px 0px 50px 0px;
        border-radius: 20px;
        margin-bottom: 10px;
    }
    
    .lineUp__title {
        margin-left: 29px;
        margin-bottom: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
        transition: all 0.5s;
    }
    
    .lineUp__diagram {
        background: url('../../../Media/lineUpDiagram_744.svg') center no-repeat;
        background-size: contain;
        height: 790px;
        width: 665px;
        margin: 0 auto;
        z-index: 50;
        transition: all 0.5s;
    }

}