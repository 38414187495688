@media screen and (min-width: 960px) and (max-width: 1279px) {

    .topBlock__wrapper {
        height: 100vh;
        max-height: 870px;
        min-height: 569px;
        width: 100%;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        box-sizing: border-box;
    }
    
    .topBlock__banner {
        height: calc(100vh - 224px);
        max-height: 646px;
        min-height: 345px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        border-radius: 20px;
        padding: 30px 0px 30px 0px;
        width: calc(50% - 5px);
        max-width: calc(50% - 5px);
        margin-right: 5px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }
    
    .topBlock__logo {
        height: 32px;
        margin-left: 30px;
        align-self: start;
        transition: all 0.5s;
    }
    
    .topBlock__banner-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .topBlock__banner-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .topBlock__nav-wrapper {
        align-self: flex-end;
    }
    
    .topBlock__titles {
        margin-bottom: 10px;
    } 
    
    .topBlock__title {
        margin-bottom: 18px;
        margin-left: 27px;
        transition: all 0.5s;
    }
    
    .topBlock__label {
        margin-left: 29px;
        transition: all 0.5s;
    }
    
    .topBlock__stamp {
        background: url('../../../Media/stamp.png') center no-repeat;
        background-size: contain;
        height: 77px;
        width: 77px;
        align-self: flex-end;
        margin-right: 40px;
        transition: all 0.5s;
    }
    
    .topBlock__partners {
        height: 194px;
        width: calc(50% - 5px);
        max-width: calc(50% - 5px);
        background-color: #141414;
        border-radius: 20px;
        padding: 20px 0px;
        box-sizing: border-box;
    }
    
    .topBlock__partners-title {
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        margin-left: 29px;
        margin-bottom: 16px;
    }
    
    .topBlock__partners-label {
        display: none;
    }

    .topBlock__ticker {
        position: relative;
        display: flex;
        overflow: hidden;
        user-select: none;
        gap: 70px;
        width: 100%;
        margin-bottom: 18px;
        box-sizing: border-box;
    }

    .topBlock__ticker-items {
        flex-shrink: 0;
        display: flex;
        gap: 70px;
        box-sizing: border-box;
      }
      
    .topBlock__ticker-item {
        height: 100px;
        transition: all 0.1s ease-in-out;
    }
    
    .marquee {
        animation: scroll 120s linear infinite;
    }
      
    @keyframes scroll {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(calc(-100% - 70px));
        }
    }
    
    .topBlock__slider {
        height: 100%;
        min-height: 549px;
        width: calc(50% - 5px);
        max-width: calc(50% - 5px);
        margin-left: 5px;
        box-sizing: border-box;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
    }

    .topBlock__slider-title {
        position: absolute;
        color: #fff;
        z-index: 99;
        top: 47px;
        left: 50px;
        font-size: 16px;
    }

    .topBlock__slide {
        position: relative;
    }

    .topBlock__slide-img {
        height: calc(100vh - 20px);
        max-height: 850px;
        min-height: 549px;
        width: 100%;
        border-radius: 20px;
    }

    .topBlock__slide-card {
        position: absolute;
        width: 100%;
        height: 116px;
        padding: 0px 30px;
        left: 0;
        bottom: 52px;
        right: 0;
        margin: auto;
        box-sizing: border-box;
    }

    .topBlock__slide-content {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(5px);
        border-radius: 26px;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    .topBlock__slide-icon {
        height: 56px;
        width: 56px;
        min-width: 56px;
        border-radius: 16px;
    }

    .topBlock__slide-title {
        flex-grow: 1;
        padding-left: 21px;
    }

    .topBlock__slide-title h3 {
        font-weight: 500;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
    }

    .topBlock__slide-title p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .topBlock__slide-case {
        display: none;
    }

}
