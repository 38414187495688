@import url('./SolutionsList_744.css');

@media screen and (min-width: 744px) {
 
    .solutionsList {
        display: flex;
        width: 100%;
        gap: 10px;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    .solution {
        height: 450px;
        max-width: 509px;
        padding: 40px 5px 5px 5px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 49%;
    }

    .solution-big {
        flex: 49%;
        max-width: 827px;
        min-width: 300px;
    }

    .solution:last-child {
        width: 100%;
        max-width: none;
    }

    .solution h3 {
        margin: 0px 35px;
        max-width: 407px;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .solution__img {
        height: 250px;
        width: 100%;
        background-color: #BDBDBD;
        border-radius: 16px;
    }

}

