@import url('./CaseHeader_375.css');

@media screen and (min-width: 744px) {
    
    .caseHeader__banner {
        height: 350px;
        width: 100%;
        padding: 40px 0px 0px 40px;
        margin: 10px 0px;
        border-radius: 20px; 
        display: flex;
        justify-content: space-between;  
        position: relative;
        overflow: hidden;
    }

    .caseHeader__banner a {
        height: 32px;
        width: 137px;
    }
    
    .caseHeader__img {
        height: 100%;
        width: 100%;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        object-fit:cover;
        z-index: -1;
    }

}
