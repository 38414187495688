@media screen and (max-width: 743px) {

    .caseCard {
        width: 100%;
        max-width: 628px;
        min-width: 281px;
        height: 281px;
        border-radius: 20px;
    }
    
    .caseCard__head {
        height: 100px;
        border-radius: 20px 20px 0px 0px;
        position: relative;
        overflow: hidden;
    }
    
    .caseCard__head-img {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0px 0px;
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        object-fit:cover;

    }
    
    .caseCard__logo {
        height: 50px;
        width: 50px;
        position: absolute;
        top: 25px;
        right: 25px;
        z-index: 99;
    }
    
    .caseCard__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 30px 30px 30px;
        height: calc(100% - 100px);
    }
    
    .caseCard__mid {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
    
    .caseCard__name {
        margin-top: 20px;
    }
    
    .caseCard__name h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }
    
    .caseCard__name p {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #6D6D6D;
    }
    
    .caseCard__rating {
        display: flex;
        margin-top: 25px;
        gap: 5px;
    }
    
    .caseCard__rating p {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #141414;
    }
    
    .caseCard__rating-star {
        height: 14px;
        width: 14px;
        background: url('../../../Media/RatingStar.svg') center no-repeat;
        background-size: contain;
    }

    .caseCard__ready-mechanics {
        display: flex;
        margin-top: 25px;
        gap: 8px;
    }
    
    .caseCard__ready-mechanics p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #141414;
    }
    
    .caseCard__on-ready {
        height: 8px;
        width: 8px;
        background-color: #CEEB1A;
        border-radius: 50%;
        margin-top: 5px;
    }
    
    .caseCard__description {
        max-width: 287px;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        display: flex;
        align-items: flex-end;
        color: #6D6D6D;
    }
    
}
