@import url('./RateCase_375.css');

@media screen and (min-width: 744px) {

    .rateCase__wrapper {
        display: flex;
        gap: 10px;
    }

    .rateCase__wrapper > div {
        height: 390px;
        width: 100%;
        background: #fff;
    }

    .rateCase__specification {
        max-width: 987px;
        padding: 40px;
        border-radius: 20px;
    }

    .rateCase__specification h2 {
        margin-bottom: 18px;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .rateCase__specification p {
        max-width: 398px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .rateCase__rating {
        max-width: 390px;
        border-radius: 20px;
        padding: 70px 0px 40px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .rateCase__rating-qr {
        height: 119px;
        width: 119px;
        margin-bottom: 82px;
    }

    .rateCase__rating-label {
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        text-align: center;
        color: #6D6D6D;
        max-width: 116px;
        margin-bottom: 10px;
    }

    .rateCase__rating-value {
        font-weight: 400;
        font-size: 46px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #141414;
    }

}