@import url('./WriteUs_375.css');


@media screen and (min-width: 960px) {
    
        .writeUs__wrapper {
        height: 450px;
        padding: 40px 0px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .writeUs__title h2 {
        margin-left: 39px;
        margin-bottom: 18px;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .writeUs__title p {
        margin-left: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .writeUs__bottom {
        padding: 0px 40px;
        display: flex;
        justify-content: space-between;
    }

    .writeUs__btn {
        display: block;
        height: 70px;
        width: 315px;
        background-color: #141414;
        color: #fff;
        padding: 21px 86px 23px 86px;
        border-radius: 20px;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        transition: all 0.5s;
    }

    .writeUs__cutaway {
        display: flex;
    }

    .writeUs__cutaway p {
        align-self: flex-end;
        max-width: 104px;
        text-align: right;
        margin-right: 20px;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: #141414;
    }

    .writeUs__qr-code {
        height: 65px;
        width: 65px;
        background: url('../../../Media/PresentationQr.svg') center no-repeat;
        background-size: contain;
        align-self: flex-end;
    }

}
