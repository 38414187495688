@media screen and (max-width: 744px) {
    
    .caseInfo__wrapper {
        display: flex;
        flex-direction: column;
        /* gap: 5px; */
        margin-bottom: 5px;
    }

    .caseInfo__main {
        /* height: 368px; */
        width: 100%;
        /* max-width: 509px; */
        padding: 20px 25px 133px 0px;
        background-color: #fff;
        border-radius: 20px 20px 0px 0px;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
    }

    .caseInfo__main-inf {
        display: flex;
        /* flex-direction: column; */
        justify-content: space-between;
    }

    .caseInfo__main-inf h1 {
        margin-left: 22px;
        font-weight: 400;
        font-size: 42px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #141414;
        margin-bottom: 15px;
    }

    .caseInfo__main-inf h2 {
        margin-left: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .caseInfo__rating {
        margin-left: 40px;
        font-size: 16px;
        color: #141414;
        display: flex;
        /* align-items: center; */
        margin-top: 5px;
        gap: 5px;
    }

    .caseInfo__rating-star {
        height: 14px;
        width: 15px;
        background: url('../../../Media/RatingStar.svg') center no-repeat;
        background-size: 15px 14px;
    }

    .caseInfo__year {
        margin-left: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #141414;
    }

    .caseInfo__more {
        width: 100%;
        max-width: 827px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .caseInfo__task {
        /* height: 240px; */
        width: 100%;
        padding: 0px 25px 25px 24px;
        background-color: #fff;
        border-radius: 0px 0px 20px 20px;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .caseInfo__task h2,p {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
    }

    .caseInfo__task h2 {
        display: none;
    }

    .caseInfo__task p {
        color: #6D6D6D;
        max-width: 588px;
    }

    .caseInfo__term {
        height: 200px;
        width: 100%;
        padding: 20px 20px 9px 24px;
        border-radius: 20px;
        background-color: #141414;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .caseInfo__term h2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #BDBDBD;
    }

    .caseInfo__term p {
        font-weight: 400;
        font-size: 150px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        align-self: end;
    }

}

