@import url("./FeedbackForm_960.css");
@import url("./FeedbackForm_744.css");
@import url("./FeedbackForm_375.css");


@media screen and (min-width: 1280px) {

    .feedbackForm {
        height: 654px;
        width: 100%;
        max-width: 508px;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 20px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .feedbackForm form {
        flex-grow: 1;
    }
    
    .feedbackForm__heading {
        height: 66px;
        display: flex;
        align-items: center;
        padding-left: 40px;
    }
    
    .feedbackForm__heading-title {
        font-family: 'TT Hoves', sans-serif;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #6D6D6D;
        margin-right: 10px;
    }
    
    .feedbackForm__heading-request {
        height: 43px;
        width: 106px;
        padding: 7px 17px 10px 17px;
        background: #E9E9E9;
        border-radius: 30px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414
    }

    .feedbackForm__input-wrapper {
        height: 66px;
        width: 100%;
        border-top: 1px solid #E7E7E7;
        display: flex;
        align-items: center;
        padding: 0px 40px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
    }

    .feedbackForm__input {
        font-family: 'TT Hoves', sans-serif;
        width: 100%;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
        box-sizing: border-box;
        padding-right: 20px;
        resize: none;
    }

    .feedbackForm__input::placeholder {
        color: #6D6D6D;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
    }

    .textarea {
        align-items: flex-start;
        height: 260px;
        box-sizing: border-box;
        padding-top: 22px;
    }

    .textarea .feedbackForm__required {
        margin-top: 7px;
    }

    .feedbackForm__required {
        align-self: flex-start;
        margin-top: 22px;
    }

    .feedbackForm__bottom {
        width: 100%;
        height: 76px;
        padding: 0px 40px;
        /* border: 1px solid black; */
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .feedbackForm__rules {
        max-width: 300px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .feedbackForm__rules a {
        text-decoration: underline;
    }

    .feedbackForm__button {
        height: 70px;
        width: 70px;
        border-radius: 20px;
        background: url('../../../Media/ButtonArrow.svg') center no-repeat;
        background-size: 23px;
        background-color: #141414;
        cursor: pointer;
        transition: all 0.2s;
    }

    .feedbackForm__button:hover {
        height: 76px;
        width: 76px;
    }

}