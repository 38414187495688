@import url('./Vendor/Fonts/TT\ Hoves/fonts.css');

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    /* font-family: 'TT Hoves', 'Helvetica Neue', */
    font-family: 'TT Hoves',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #E9E9E9;
}