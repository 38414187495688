@media screen and (max-width: 744px) {

    .strengths__header {
        height: 300px;
        width: 100%;
        background-color: #141414;
        border-radius: 20px;
        padding: 20px 25px 25px 22px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 5px;
        transition: all 0.5s;
    }
    
    .strengths__header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 94%;
        color: #fff;
        letter-spacing: -0.03em;
        max-width: 240px;
    }
    
    .strengths__header-text {
        align-self: flex-end;
        color: #fff;
        font-size: 14px;
    }
    
    .strengths__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    
    .strength__card {
        height: 407px;
        flex: 100%;
        background-color: #fff;
        border-radius: 20px;
        box-sizing: border-box;
        margin-bottom: 5px;
        padding: 60px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transition: all 0.5s;
    }

    .clientsReview {
        height: 458px;
    }

    .clientsReview .strength__card-text {
        margin-top: 50px;
    }
    
    .strength__card-head {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .strength__card-head h3 {
        max-width: 231px;
        text-align: center;
        font-size: 22px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #141414;
    }
    
    .strength__card-head {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .strength__card-head h3 {
        font-size: 18px;
        font-weight: 500;
    }
    
    .strength__card-icon {
        height: 65px;
        width: 65px;
        border-radius: 18px;
        margin-bottom: 20px;
        background-size: 18px;
        transition: all 0.5s;
    }
    
    .strength__card-text {
        max-width: 315px;
        font-size: 14px;
        text-align: center;
        transition: all 0.5s;
    }

    .strength__card-client {
        text-align: center;
    }

    .strength__card-client h4 {
        font-size: 14px;
        line-height: 120%;
        color: #141414;
    }

    .strength__card-client p {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #6D6D6D;
    }

}