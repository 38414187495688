.footer {
    height: 112px;
    border-radius: 20px;
    padding: 0px 40px;
    background-color: #E9E9E9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__right p {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #6D6D6D;
}

.footer__links {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #6D6D6D;
}

.footer__link {
    margin-right: 25px;
}

.footer__link:hover {
    text-decoration: underline;
}

.footer__soc-netw {
    height: 38px;
    width: 38px;
    border: 1px solid #BDBDBD;
    border-radius: 12px;
    transition: background-color 0.3s;
}

.footer__soc-netw:hover {
    background-color: #BDBDBD;
}

.telegram {
    background: url('../../../Media/TelegramIcon.svg') center no-repeat;
    background-size: 17px;
    margin-right: 7px;
}

.youtube {
    background: url('../../../Media/YouTubeIcon.svg') center no-repeat;
    background-size: 17px;;
}

@media screen and (max-width: 960px) {
    .footer {
        height: 97px;
        padding-top: 30px;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (max-width: 744px) {
    .footer {
        height: 97px;
        padding-top: 30px;
        justify-content: center;
        align-items: flex-start;
    }
}