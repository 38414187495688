.navigation {
    width: 75px;
    margin-right: 42px;
    margin-top: 7px;
}

.navigation__list {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #141414;
}

.navigation__list.nav-white {
    color: #fff;
}

.navigation__list-item {
    margin-bottom: 14px;
}

.navigation__list-item a:hover {
    text-decoration: underline;
}

.drop-down-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.drop-down-arrow {
    height: 5px;
    width: 8px;
    margin-left: 8px;
    background: url('../../../Media/Arrow.svg') center no-repeat;
    background-size: contain;
    transition: all 0.5s;
}

.drop-down-arrow.arrow-white {
    background: url('../../../Media/ArrowWhite.svg') center no-repeat;
}

.arrow-acrive {
    transform: rotate(180deg);
}

.navigation__submenu {
    margin-left: 10px;
    margin-top: 14px;
    color: #141414;
}

.navigation__submenu.nav-white {
    color: #fff;
}

.submenu__item {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
}