@import url('./CompetenceHead_960.css');
@import url('./CompetenceHead_744.css');
@import url('./CompetenceHead_375.css');

@media screen and (min-width: 1124px) {
    
    .competenceHead__wrapper {
        display: flex;
        gap: 10px;
        padding: 10px 0px;
    }
    
    .competenceHead__banner {
        height: 500px;
        width: 100%;
        max-width: 828px;
        min-width: 576px;
        padding: 40px 0px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .competenceHead__banner-top {
        display: flex;
        justify-content: space-between;
    }

    .competenceHead__banner-top a {
        height: 32px;
    }
    
    .competenceHead__logo {
        height: 32px;
        margin-left: 40px;
        align-self: start;
        transition: all 0.5s;
    }
    
    .competenceHead__title {
        margin-left: 37px;
        margin-bottom: 18px;
        transition: all 0.5s;
    }
    
    .competenceHead__label {
        margin-left: 40px;
        max-width: 430px;
        transition: all 0.5s;
    }
    
    .competenceHead__card {
        height: 500px;
        width: 508px;
        min-width: 508px;
        padding: 47px 40px 40px 40px;
        background-color: #141414;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.5s;
    }
    
    .competenceHead__card p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #fff;
        transition: all 0.5s;
    }
    
    .competenceHead__card-label-top {
        align-self: flex-end;
        margin-bottom: 126px;
    }
    
    .competenceHead__card-icon {
        height: 120px;
        width: 120px;
        border-radius: 33px;
        background-size: 50px;
        margin-bottom: 116px;
    }
    
    .competenceHead__card-label-bottom {
        align-self: flex-start;
        max-width: 182px;
    }

}
