.promo__wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.promo__content {
    height: 627px;
    width: 100%;
    max-width: 828px;
    padding: 40px;
    background-color: #fff;
    border-radius: 20px;
}

.promo__content h2 {
    font-weight: 400;
    font-size: 26px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #141414;
    margin-bottom: 18px;
}

.promo__content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #6D6D6D;
    margin-bottom: 60px;
}

.promo__slider {
    height: 421px;
    width: 100%;
    background-color: #141414;
    border-radius: 20px;
}

.promo__inf {
    height: 627px;
    width: 100%;
    max-width: 508px;
    padding: 40px 40px 48px 40px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.promo__inf h2, p {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #141414;
}

.promo__inf h2 {
    margin-bottom: 28px;
}

.promo__inf p {
    color: #6D6D6D;
}

.promo__tools h2 {
    margin-bottom: 23px;
}

.promo__tools-list {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.promo__tool {
    padding: 5px 12px 6px 12px;
    border: 1px solid #BDBDBD;
    border-radius: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #6D6D6D;
}