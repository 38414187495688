@import url('./ContactsWriteUs_744.css');

@media screen and (min-width: 960px) {
    
    .contactsWriteUs__wrapper {
        display: flex;
        gap: 10px;
    }

    .contactsWriteUs__info {
        height: 654px;
        width: 100%;
        max-width: 828px;
        padding: 40px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .contactsWriteUs__title h2 {
        margin-bottom: 18px;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .contactsWriteUs__title p {
        max-width: 350px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .contactsWriteUs__data {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .contactsWriteUs__phone, .contactsWriteUs__email {
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
    }

    .contactsWriteUs__email {
        text-decoration: underline;
    }

}

@media screen and (min-width: 960px) and (max-width: 1279px) {
    .contactsWriteUs__info {
        height: 644px;
    }
}

@media screen and (min-width: 744px) and (max-width: 960px) {
    .contactsWriteUs__wrapper {
        gap: 10px;
    }
}