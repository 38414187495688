@media screen and (max-width: 744px) {

    .contactBlock__wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }
    
    .contactBlock__card {
        height: 418px;
        width: 100%;
        padding: 25px 0px 30px 0px;
        background: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
    }
    
    .contactBlock__title {
        margin-left: 22px;
        color: #141414;
        font-weight: 400;
        font-size: 42px;
        line-height: 94%;
        letter-spacing: -0.03em;
        margin-bottom: 15px;
    }
    
    .contactBlock__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
        margin-left: 24px;
        max-width: 190px;
    }
    
    .contactBlock__contacts {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    
    .contactBlock__inf {
        display: flex;
        justify-content: space-between;
        padding: 0px 25px 0px 24px;
    }
    
    .contactBlock__phone {
        margin-bottom: 14px;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
    }
    
    .contactBlock__email {
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: -0.02em;
        text-decoration: underline;
        color: #141414;
    }

    .contactBlock__email:hover {
        text-decoration: none;
    }
    
    .contactBlock__cutaway {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    
    .contactBlock__cutaway p {
        align-self: flex-end;
        max-width: 104px;
        text-align: right;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: #141414;
        text-align: center;
    }
    
    .contactBlock__qr-code {
        height: 65px;
        width: 65px;
        background: url('../../../Media/BusinessCardLink.svg') center no-repeat;
        background-size: contain;
        margin-bottom: 10px;
    }
}