@media screen and (min-width: 960px) and (max-width: 1279px) {

    .contactBlock__wrapper {
        margin-top: 10px;
        display: flex;
        gap: 10px;
    }
    
    .contactBlock__card {
        height: 644px;
        width: 100%;
        padding: 30px 0px;
        background: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
    }
    
    .contactBlock__title {
        margin-left: 27px;
        color: #141414;
        font-weight: 400;
        font-size: 46px;
        line-height: 94%;
        letter-spacing: -0.03em;
        margin-bottom: 12px;
    }
    
    .contactBlock__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
        margin-left: 29px;
        max-width: 310px;
    }
    
    .contactBlock__contacts {
        display: flex;
        flex-direction: column;
    }
    
    .contactBlock__inf {
        display: flex;
        justify-content: space-between;
        padding: 0px 30px;
    }
    
    .contactBlock__phone {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
    }
    
    .contactBlock__email {
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        text-decoration: underline;
        color: #141414;
    }

    .contactBlock__email:hover {
        text-decoration: none;
    }
    
    .contactBlock__cutaway {
        display: flex;
    }
    
    .contactBlock__cutaway p {
        align-self: flex-end;
        max-width: 104px;
        text-align: right;
        margin-right: 20px;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: #141414;
    }
    
    .contactBlock__qr-code {
        height: 65px;
        width: 65px;
        background: url('../../../Media/PresentationQr.svg') center no-repeat;
        background-size: contain;
    }
    
}